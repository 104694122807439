import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PageHeading from '../components/PageHeading';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  pageContext
}) => <Layout fullWidth editLink={pageContext.fileRelativePath}>
    <SEO title="Contributing to a New Relic Open Source Project" />
    <PageHeading title="New Relic Open Source Standards" subheader="Guidance for how we implement New Relic core values in open source" />
    <div className="primary-content">
      <main>{children}</main>
    </div>
  </Layout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2><a parentName="h2" {...{
        "href": "/oss-category"
      }}>{`Open Source Categories`}</a></h2>
    <p>{`All New Relic open source projects are `}<a parentName="p" {...{
        "href": "/oss-category"
      }}><strong parentName="a">{`categorized`}</strong></a>{` to help contributors, developers, and customers understand what to expect and how to engage with our projects.`}</p>
    <h2><a parentName="h2" {...{
        "href": "/contributing"
      }}>{`Contributing`}</a></h2>
    <p>{`New Relic open source projects follow a `}<a parentName="p" {...{
        "href": "https://gist.github.com/tangollama/5d9d63fef0ed4498fb5fbb057bb5983d",
        "target": "__blank",
        "rel": "noopener noreferrer"
      }}>{`fork-and-pull-request`}</a>{` workflow for project contribution. If you're new to open source contribution at New Relic, the content on `}<a parentName="p" {...{
        "href": "/contributing"
      }}><strong parentName="a">{`this page`}</strong></a>{` will help.`}</p>
    <h2><a parentName="h2" {...{
        "href": "/code-of-conduct"
      }}>{`Code of Conduct`}</a></h2>
    <p>{`Read our `}<a parentName="p" {...{
        "href": "/code-of-conduct"
      }}><strong parentName="a">{`Code of Conduct`}</strong></a>{` to understand how we operate in open source and what we expect of contributors.`}</p>
    <h3><a parentName="h3" {...{
        "href": "/code-of-conduct-reporting"
      }}>{`Code of Conduct Reporting Guide`}</a></h3>
    <p>{`Review our guidance and expectations on `}<a parentName="p" {...{
        "href": "/code-of-conduct-reporting"
      }}><strong parentName="a">{`reporting Code of Conduct`}</strong></a>{` incidents.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      